import { Axios } from 'axios';

/**
 *
 * @param {Axios} api
 * @returns
 */
export default function makeSharedStepService(api) {
  return {
    createSharedStep: function (handle, projectKey, data) {
      return api.post(`/${handle}/projects/${projectKey}/shared-steps`, data);
    },

    getSharedSteps: function (handle, projectKey) {
      return api.get(`/${handle}/projects/${projectKey}/shared-steps?includeCount=true&active=true`);
    },

    deleteSharedStep: function (handle, projectKey, uid) {
      return api.delete(`/${handle}/projects/${projectKey}/shared-steps/${uid}`);
    },

    deleteSharedSteps: function (handle, projectKey, data) {
      return api.delete(`/${handle}/projects/${projectKey}/shared-steps`, { data: data });
    },

    updateSharedStep: function (handle, projectKey, uid, data) {
      return api.patch(`/${handle}/projects/${projectKey}/shared-steps/${uid}`, data);
    },

    updateSharedSteps: function (handle, projectKey, data) {
      return api.patch(`/${handle}/projects/${projectKey}/shared-steps`, data);
    },
  };
}
