<template>
  <v-container fluid>
    <Header :status="tab" :activeCount="activeCount" :archivedCount="archivedCount" @update-status="updateFilterStatus" @create-shared-step="onCreateSharedStep" />

    <v-row>
      <v-col :cols="isDetailMode ? 9 : 12" class="pr-3">
        <v-card class="py-6 px-6 mt-3" rounded="lg" elevation="0" width="100%">
          <Placeholder v-if="!hasSteps" @action="onCreateSharedStep" :title="$t('sharedStepPage.emptyTitle')" :btnText="$t('sharedStepPage.createSharedStep')" />
          <template v-else>
            <v-row justify="space-between" class="align-center">
              <v-col cols="12" md="auto" class="d-flex align-center">
                <v-text-field :loading="loading" v-model="searchFilter" class="rounded-lg pa-0 mr-2" prepend-inner-icon="mdi-magnify" :placeholder="$t('searchBy_name')" dense filled hide-details></v-text-field>

                <FilterDialog @update-filter="updateFilter" />
              </v-col>

              <v-col cols="12" md="auto" class="mr-3">
                <v-row justify="end" class="align-center">
                  <v-menu left :close-on-content-click="false" :nudge-bottom="4" offset-y min-width="191">
                    <template v-slot:activator="{ on }">
                      <v-btn class="ml-auto pa-0 rounded-lg" height="40" min-width="40" elevation="0" v-on="on">
                        <v-icon size="16px"> mdi-cog-outline </v-icon>
                      </v-btn>
                    </template>
                    <v-list width="191" class="d-flex flex-column justify-space-between rounded-xl">
                      <v-list-item class="font-inter custom-text-14 custom-menu-item my-2">{{
                        $t('columns')
                        }}</v-list-item>
                      <v-list-item v-for="(header, index) in filteredMenuHeaders" class="custom-menu-item" :key="index">
                        <v-checkbox v-model="header.isSelected" :value="header.isSelected" :true-value="true" :false-value="false" off-icon="mdi-checkbox-blank" class="ma-0 pa-0" color="blue" :size="16" hide-details>
                          <template v-slot:label>
                            <div class="custom-color-0c111d font-inter custom-text-14">{{ header.text }}</div>
                          </template>
                        </v-checkbox>
                      </v-list-item>
                      <v-list-item class="custom-menu-item my-2" @click="handleColumnReset()">
                        <div class="custom-text-12 font-inter font-weight-semibold" :class="{ 'blue--text font-weight-semibold ': isColumnFilter }">
                          {{ $t('Restore default') }}
                        </div>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-row>
              </v-col>
            </v-row>

            <SharedStepTable :headers="listHeaders" :items="filteredSteps" itemKey="uid" @edit="handleEditItem" @delete="handleDeleteItem" @archive="handleArchiveItem" @unarchive="handleUnArchiveItem" @input="handleInput" @row-click="handleClickRow" />

            <v-row justify="end" class="mt-4" v-if="selectedItems.length > 0">
              <v-btn width="140" class="text-capitalize font-weight-medium danger mr-3 f-color-white" depressed @click="deleteSharedSteps">{{ $t('delete') }}</v-btn>
              <v-btn v-if="tab == 'active'" width="140" class="text-capitalize font-weight-medium primary mr-3" depressed @click="handleChangeActiveStatuses(true)">{{ $t('archive') }}</v-btn>
              <v-btn v-else width="140" class="text-capitalize font-weight-medium primary mr-3" depressed @click="handleChangeActiveStatuses(false)">{{ $t('unarchive') }}</v-btn>
            </v-row>
          </template>
        </v-card>
      </v-col>
      <v-col v-if="isDetailMode" cols="3">
        <SharedStepDetail :detailItem="currentItem" :hasPrevious="currentFilteredIndex > 0" :hasNext="currentFilteredIndex < filteredSteps.length - 1" @previous="navigateToPrevious" @next="navigateToNext" @edit="handleEditItem" @close="closeDetail" />
      </v-col>
    </v-row>

    <CreateUpdateDialog v-model="showCreateUpdateDialog" :data="selectedStep" @create-shared-step="createSharedStep" @update-shared-step="updateSharedStep" @close-dialog="showCreateUpdateDialog = false" />

    <confirm-dialog v-model="showDeleteConfirmDialog" :title="$t('sharedStepPage.deleteConfirm', { name: selectedStep.name })" :description="$t('sharedStepPage.deleteDescription')" :actions="[
      { label: $t('cancel'), color: 'secondary', action: 'cancel' },
      { label: $t('delete'), color: 'danger', action: 'delete' },
    ]" @cancel="showDeleteConfirmDialog = false" @close="showDeleteConfirmDialog = false" @delete="deleteSharedStepItem" />

    <confirm-dialog v-model="showArchiveConfirmDialog" :title="$t('sharedStepPage.archiveConfirm', { action: isArchive ? $t('archive') : $t('unarchive'), name: selectedStep.name })" :description="isArchive ? $t('sharedStepPage.archiveDescription') : $t('sharedStepPage.unarchiveDescription')" :actions="[
      { label: $t('cancel'), color: 'secondary', action: 'cancel' },
      { label: isArchive ? $t('archive') : $t('unarchive'), color: 'primary', action: 'archive' },
    ]" @cancel="showArchiveConfirmDialog = false" @close="showArchiveConfirmDialog = false" @archive="changeActiveStatusSharedStepItem" />

    <confirm-dialog v-model="showBulkDeleteConfirmDialog" :title="$t('sharedStepPage.bulkDeleteConfirm', { count: selectedItems.length })" :description="$t('sharedStepPage.deleteDescription')" :actions="[
      { label: $t('cancel'), color: 'secondary', action: 'cancel' },
      { label: $t('delete'), color: 'danger', action: 'delete' },
    ]" @cancel="showBulkDeleteConfirmDialog = false" @close="showBulkDeleteConfirmDialog = false" @delete="bulkDeleteSharedSteps" />

    <confirm-dialog v-model="showBulkArchiveUnarchiveDialog" :title="$t('sharedStepPage.bulkArchiveConfirm', { count: selectedItems.length, action: tab === 'active' ? $t('archive') : $t('unarchive') })" :description="isArchive ? $t('sharedStepPage.archiveDescription') : $t('sharedStepPage.unarchiveDescription')" :actions="[
      { label: $t('cancel'), color: 'secondary', action: 'cancel' },
      { label: tab === 'active' ? $t('archive') : $t('unarchive'), color: 'primary', action: 'archive' },
    ]" @cancel="showBulkArchiveUnarchiveDialog = false" @close="showBulkArchiveUnarchiveDialog = false" @archive="bulkChangeStatusSharedSteps" />
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import makeSharedStepService from '@/services/api/sharedStep';
import Header from '@/components/Admin/SharedStep/Header';
import Placeholder from '@/components/base/Placeholder.vue';
import SharedStepTable from '@/components/Admin/SharedStep/SharedStepTable.vue';
import FilterDialog from '@/components/Admin/SharedStep/FilterDialog.vue';
import CreateUpdateDialog from '@/components/Admin/SharedStep/CreateUpdateDialog.vue';
import { formatDate } from '@/utils/util';
import { showSuccessToast, showErrorToast } from '@/utils/toast';
import { SharedStepTableHeader } from '@/constants/grid';
import ConfirmDialog from '@/views/Admin/SharedStep/ConfirmDialog.vue';
import SharedStepDetail from '@/components/Admin/SharedStep/SharedStepDetail.vue';

const { mapState } = createNamespacedHelpers('user');
let sharedStepService;

export default {
  name: 'SharedStep',

  components: {
    Header,
    SharedStepTable,
    Placeholder,
    FilterDialog,
    CreateUpdateDialog,
    ConfirmDialog,
    SharedStepDetail,
  },

  data()
  {
    return {
      projectKey: this.$route.params.key,
      loading: false,
      isDetailMode: false,
      headers: SharedStepTableHeader,
      tableData: [],
      isColumnFilter: false,
      searchFilter: '',
      stepRangeFilter: [],
      referencedByRangeFilter: [],
      showCreateUpdateDialog: false,

      tab: 'active',
      selectedStep: {
        uid: '',
        name: '',
        steps: [],
      },
      showArchiveConfirmDialog: false,
      showDeleteConfirmDialog: false,
      showBulkDeleteConfirmDialog: false,
      showBulkArchiveUnarchiveDialog: false,
      isArchive: true,
      selectedItems: [],
      detailItem: '',

      originalTags: [],
      filteredTags: [],
      isLoading: false,
      errorMessage: '',
      currentIndex: 0,
    };
  },

  computed: {
    ...mapState(['currentAccount']),

    filteredSteps()
    {
      let filteredStepsData = this.tableData;

      if (this.tab) {
        filteredStepsData = filteredStepsData.filter(
          (item) => (this.tab === 'active' && !item.archivedAt) || (this.tab === 'archived' && item.archivedAt)
        );
      }

      if (this.searchFilter) {
        filteredStepsData = filteredStepsData.filter((item) =>
          item.name.toLowerCase().includes(this.searchFilter.toLowerCase())
        );
      }

      if (this.stepRangeFilter.length > 0) {
        filteredStepsData = filteredStepsData.filter(
          (item) => this.stepRangeFilter[0] <= item.steps.length && this.stepRangeFilter[1] >= item.steps.length
        );
      }

      if (this.referencedByRangeFilter.length > 0) {
        filteredStepsData = filteredStepsData.filter(
          (item) =>
            this.referencedByRangeFilter[0] <= item.steps.length && this.referencedByRangeFilter[1] >= item.steps.length
        );
      }

      return filteredStepsData;
    },

    filteredMenuHeaders()
    {
      const filtered = this.headers.filter((header) => header.value != 'actions');
      return filtered;
    },

    listHeaders()
    {
      const filtered = this.headers.filter((x) => x.isSelected);
      if (filtered.length < this.headers.length)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isColumnFilter = true;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      else this.isColumnFilter = false;
      return filtered;
    },

    activeCount()
    {
      return this.tableData.filter((item) => !item.archivedAt).length;
    },

    archivedCount()
    {
      return this.tableData.filter((item) => !!item.archivedAt).length;
    },

    hasSteps()
    {
      return this.tableData.length > 0;
    },

    currentItem()
    {
      return this.detailItem || (this.filteredSteps.length > 0 ? this.filteredSteps[0] : null);
    },

    currentFilteredIndex()
    {
      return this.filteredSteps.findIndex((step) => step.uid === this.currentItem.uid);
    },
  },

  mounted()
  {
    this.getSharedSteps();
  },

  created()
  {
    sharedStepService = makeSharedStepService(this.$api);
  },

  methods: {
    updateFilter(isDefault, stepRange, referenceRange)
    {
      if (isDefault == false) {
        this.stepRangeFilter = stepRange;
        this.referencedByRangeFilter = referenceRange;
      } else {
        this.stepRangeFilter = [];
        this.referencedByRangeFilter = [];
      }
      // this.filteredSteps();
    },
    formatCreatedAt(createdAt)
    {
      return formatDate(createdAt, 'MM/dd/yy');
    },

    handleColumnReset()
    {
      this.headers = this.headers.map((header) =>
      {
        header.isSelected = true;
        return header;
      });
    },

    handleInput(selectedItems)
    {
      this.selectedItems = selectedItems;
    },

    handleDeleteItem(item)
    {
      this.showDeleteConfirmDialog = true;
      this.selectedStep = item;
    },

    handleUnArchiveItem(item)
    {
      this.isArchive = false;
      this.showArchiveConfirmDialog = true;
      this.selectedStep = item;
    },

    handleArchiveItem(item)
    {
      this.isArchive = true;
      this.showArchiveConfirmDialog = true;
      this.selectedStep = item;
    },

    deleteSharedSteps()
    {
      this.showBulkDeleteConfirmDialog = true;
    },

    async bulkDeleteSharedSteps()
    {
      this.showBulkDeleteConfirmDialog = false;
      const sharedStepIds = this.selectedItems.map(item => item.uid);
      const payload = { sharedStepIds };
      try {
        const response = await sharedStepService.deleteSharedSteps(
          this.currentAccount.handle,
          this.projectKey,
          payload
        );
        if (response.status == 200) {
          showSuccessToast(this.$swal, 'deleteSuccess', { item: 'Shared step' });
          this.getSharedSteps();
        }
      } catch (err) {
        showErrorToast(this.$swal, 'deleteError', { item: 'Shared step' });
      }
    },

    handleChangeActiveStatuses(status)
    {
      this.isArchive = status;
      this.showBulkArchiveUnarchiveDialog = true;
    },

    async bulkChangeStatusSharedSteps()
    {
      this.showBulkArchiveUnarchiveDialog = false;
      const payload = this.selectedItems.map(item => ({
        id: item.uid,
        archived: this.tab != 'archived',
      }));
      try {
        const response = await sharedStepService.updateSharedSteps(
          this.currentAccount.handle,
          this.projectKey,
          { sharedSteps: payload }
        );
        if (response.status == 200) {
          showSuccessToast(this.$swal, this.tab != 'archived' ? 'archiveSuccess' : 'unarchiveSuccess', { item: 'Shared step' });
          this.getSharedSteps();
        }
      } catch (err) {
        showErrorToast(this.$swal, this.tab != 'archived' ? 'archiveError' : 'unarchiveError', { item: 'Shared step' });
      }
    },

    async changeActiveStatusSharedStepItem()
    {
      this.showArchiveConfirmDialog = false;
      const payload = [{
        id: this.selectedStep.uid,
        archived: this.tab != 'archived',
      }];
      try {
        const response = await sharedStepService.updateSharedSteps(
          this.currentAccount.handle,
          this.projectKey,
          { sharedSteps: payload }
        );
        if (response.status == 200) {
          showSuccessToast(this.$swal, this.tab != 'archived' ? 'archiveSuccess' : 'unarchiveSuccess', { item: 'Shared step' });
          this.getSharedSteps();
        }
      } catch (err) {
        showErrorToast(this.$swal, this.tab != 'archived' ? 'archiveError' : 'unarchiveError', { item: 'Shared step' });
      }
    },

    async updateSharedStep(sharedStep)
    {
      this.showCreateUpdateDialog = false;
      const payload = {
        name: sharedStep.name,
        description: sharedStep.description,
        expectedResult: sharedStep.expectedResult,
        steps: sharedStep.steps,
      };
      try {
        const response = await sharedStepService.updateSharedStep(
          this.currentAccount.handle,
          this.projectKey,
          sharedStep.uid,
          payload
        );
        if (response.status == 200) {
          showSuccessToast(this.$swal, 'updateSuccess', { item: 'Shared step' });
          this.getSharedSteps();
        }
      } catch (err) {
        showErrorToast(this.$swal, 'updateError', { item: 'Shared step' });
      }
    },

    async createSharedStep(sharedStep)
    {
      this.showCreateUpdateDialog = false;
      this.isLoading = true;
      const data = {
        name: sharedStep.name,
        steps: sharedStep.steps,
      };
      try {
        const response = await sharedStepService.createSharedStep(
          this.currentAccount.handle,
          this.projectKey,
          data
        );
        if (response.status == 200) {
          showSuccessToast(this.$swal, 'createSuccess', { item: 'Shared step' });
          this.getSharedSteps();
        } else {
          showErrorToast(this.$swal, 'createError', { item: 'Shared step' });
        }
      } catch (err) {
        showErrorToast(this.$swal, 'createError', { item: 'Shared step' });
      }
    },

    async deleteSharedStepItem()
    {
      this.showDeleteConfirmDialog = false;
      try {
        const response = await sharedStepService.deleteSharedStep(
          this.currentAccount.handle,
          this.projectKey,
          this.selectedStep.uid
        );
        if (response.status == 200) {
          showSuccessToast(this.$swal, 'deleteSuccess', { item: 'Shared step' });
          this.getSharedSteps();
        }
      } catch (err) {
        showErrorToast(this.$swal, 'deleteError', { item: 'Shared step' });
      }
    },

    handleClickRow(item)
    {
      this.isDetailMode = true;
      this.detailItem = item;
    },

    closeDetail()
    {
      this.isDetailMode = false;
    },

    onCreateSharedStep()
    {
      (this.selectedStep = {
        uid: '',
        name: '',
        steps: [],
      }),
        (this.showCreateUpdateDialog = true);
    },

    handleEditItem(item)
    {
      this.selectedStep = item;
      this.showCreateUpdateDialog = true;
    },

    async getSharedSteps()
    {
      this.isLoading = true;
      this.isDetailMode = false;
      this.detailItem = '';
      try {
        const response = await sharedStepService.getSharedSteps(
          this.currentAccount.handle,
          this.projectKey
        );
        if (response.status == 200) {
          this.tableData = response.data.sharedSteps;
        }
      } catch (err) {
        console.log(err);
      }
    },

    updateFilterStatus(value)
    {
      this.tab = value;
      // this.filteredSteps();
    },

    navigateToPrevious()
    {
      if (this.currentFilteredIndex > 0) {
        this.detailItem = this.filteredSteps[this.currentFilteredIndex - 1];
      }
    },

    navigateToNext()
    {
      if (this.currentFilteredIndex < this.filteredSteps.length - 1) {
        this.detailItem = this.filteredSteps[this.currentFilteredIndex + 1];
      }
    },
  },
};
</script>

<style>
p.mb-0 {
  font-size: 14px;
  color: #0c111d;
}

h5.used-in {
  font-size: 14px;
  color: #667085;
}
</style>
